<template>
  <v-row no-gutters>
    <v-col sm="5" cols="12">
      <fieldsValuesContainer
        field="c_name"
        :result="{ ...result, enableAdd: true }"
        v-on="$listeners"
        :valueLine="result.valueLine"
      ></fieldsValuesContainer>
      <fieldsValuesContainer
        field="category_tag"
        :result="{ ...result, enableAdd: true }"
        v-on="$listeners"
        :valueLine="result.valueLine"
      ></fieldsValuesContainer
    ></v-col>
    <v-col sm="7" cols="12">
      <fieldsValuesContainer
        field="c_description"
        :result="{ ...result }"
        v-on="$listeners"
        :valueLine="result.valueLine"
      ></fieldsValuesContainer>
    </v-col>
  </v-row>
</template>
<script>
import fieldsValuesContainer from "@/commonComponents/fieldsValuesContainer.vue";
export default {
  components: { fieldsValuesContainer },
  props: ["result"],
};
</script>
<!--<style>
.testDescription textarea {
  height: 100px !important;
}
</style>-->
